<button *ngIf="isDebug()" class="btn btn-primary text-uppercase col-10" type="submit" (click)="update()">Debug
</button>
<app-progress-bar step="1"></app-progress-bar>
<div *ngIf="this.metaInfoService.hasInfo('product_selection_headline')" class="small-container text-center mx-auto">
  <h1>{{ this.metaInfoService.getInfoValue('product_selection_headline') }}</h1>
</div>

<div [hidden]="loading">
  <div class="small-container mx-auto">
    <!-- / row /-->
    <div class="row">
      <div class="col-60 col-lg-30 order-1 form-group">
        <div class="w-100 d-flex label-container">
          <label>Ladeleistung<span class="required">*</span></label>
          <app-tooltip
            [message]="this.metaInfoService.getInfoValue('product_selection_charging_capacity')"
            [large]="true"
          ></app-tooltip>
        </div>
        <ng-select [items]="chargingCapacities"
                   bindLabel="name"
                   [placeholder]="'bitte wählen...'"
                   (change)="onSelectChange('chargingCapacities')"
                   [(ngModel)]="selection.chargingCapacity"
                   [clearable]="false">
        </ng-select>
      </div>
      <div class="col-60 col-lg-30 order-4 order-lg-2 form-group">
        <div class="w-100 d-flex label-container">
          <label>{{ this.metaInfoService.getInfoValue('product_selection_label_accessories') }}</label>
          <app-tooltip
            [message]="this.metaInfoService.getInfoValue('product_selection_accessories')"
            [large]="true"
          ></app-tooltip>
        </div>
        <!-- todo multiple must be changed to true! -->
        <ng-select [items]="accessories"
                   #accessoriesSelect
                   [multiple]="true"
                   [searchable]="false"
                   [closeOnSelect]="false"
                   bindLabel="name"
                   [placeholder]="!selection.installationType ? '' : ''"
                   [disabled]="!selection.installationType"
                   (change)="onSelectChange('accessories')"
                   [(ngModel)]="selection.accessories"
                   [clearable]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of (items ? items.slice(0,1): [])">
              <span *ngIf="items.length == 1" class="ng-value-label">{{item.name}}</span>
              <span *ngIf="items.length >= 2" class="ng-value-label"> {{items.length}} ausgewählt</span>
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
              <span>{{item.name}}</span>
              <i class="fas fa-times reset-btn"></i>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-60 col-lg-30 order-2 order-lg-3 form-group">
        <div class="w-100 d-flex label-container">

          <label>Anzahl der Ladepunkte<span class="required">*</span></label>
          <app-tooltip
            [message]="this.metaInfoService.getInfoValue('product_selection_charger_connections')"
            [large]="true"
          ></app-tooltip>
        </div>
        <ng-select [items]="chargerConnections"
                   bindLabel="name"
                   [placeholder]="!selection.chargingCapacity ? '' : 'bitte wählen...'"
                   [disabled]="!selection.chargingCapacity"
                   (change)="onSelectChange('chargerConnections')"
                   [(ngModel)]="selection.chargerConnections"
                   [clearable]="false">
        </ng-select>
      </div>
      <div class="col-60 col-lg-30 order-5 order-lg-4 form-group">
        <div class="w-100 d-flex label-container">

          <label>Autorisierung</label>
          <app-tooltip
            [message]="labelInfos.authorization"
            [large]="true"
          ></app-tooltip>
        </div>
        <ng-select [items]="authorizations"
                   bindLabel="name"
                   [placeholder]="!selection.installationType ? '' : ''"
                   [disabled]="!selection.installationType"
                   (change)="onSelectChange('authorizations')"
                   [(ngModel)]="selection.authorization"
                   [clearable]="true">
        </ng-select>
      </div>

      <div class="col-60 col-lg-30 order-3 order-lg-5 form-group">
        <div class="w-100 d-flex label-container">
          <label>Installationsart<span class="required">*</span></label>
          <app-tooltip
            [message]="this.metaInfoService.getInfoValue('product_selection_installation_type')"
            [large]="true"
          ></app-tooltip>
        </div>
        <ng-select [items]="installationTypes"
                   bindLabel="name"
                   [placeholder]="!selection.chargerConnections ? '' : 'bitte wählen...'"
                   [disabled]="!selection.chargerConnections"
                   [(ngModel)]="selection.installationType"
                   (change)="onSelectChange('installationType')"
                   [clearable]="false"
        >
        </ng-select>
      </div>
      <div class="col-60 col-lg-30 order-6 form-group">
        <div class="w-100 d-flex label-container">
          <label>Abrechnungsfähigkeit</label>
          <app-tooltip
            [message]="this.metaInfoService.getInfoValue('product_selection_standardized')"
            [large]="true"
          ></app-tooltip>
        </div>
        <ng-select [items]="standardized"
                   bindLabel="name"
                   [placeholder]="!selection.installationType ? '' : ''"
                   [disabled]="!selection.installationType"
                   (change)="onSelectChange('standardized')"
                   [(ngModel)]="selection.standardized"
                   [clearable]="true">
        </ng-select>
      </div>
    </div>
  </div>
</div>

<div class="lds-css ng-scope" [hidden]="!loading">
  <div class="wrapper">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p style="text-align: center !important;" [innerHTML]="loadingText"></p>
  </div>
</div>

<app-suggestion [selection]="selection" [hidden]="loading"></app-suggestion>
