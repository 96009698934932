<app-progress-bar step="2"></app-progress-bar>
<div class="row">
  <div class="col-md-60 text-center">
    <h1>{{ this.metaInfoService.getInfoValue('app_name') }}</h1>
  </div>
</div>

<div *ngIf="variant" class="row mb-3">
  <div class="col-md-60 mb-3">
    <a href="javascript:history.back()" class="btn text-button p-0"><i class="fa fa-chevron-left small mr-1"></i> zurück zur Auswahl</a>
  </div>
</div>

<div class="row">
  <div *ngIf="error" class="col-md-40">
    <div class="alert alert-danger">
      {{ error }}
    </div>
  </div>
</div>
<div class="row flex-container contact-container">
  <div *ngIf="variant" class="col-60 col-md-20 pr-md-3">
    <div class="simple-variant display-product variant">
      <h2>{{ this.metaInfoService.getInfoValue('contact_selected_product') }}</h2>
      <div class="product-chosen">
        <div class="w-100 top-container">
          <div class="inner">
            <div class="product-name">
              <h4>{{ variant.productName }}</h4>
            </div>
            <p class="power">
              {{ variant.installationType }}
              / {{ variant.chargerConnections}} x {{ variant.maximumChargingCapacity.toLocaleString() }} kW
            </p>
          </div>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px"
               viewBox="0 0 270 21" xml:space="preserve"  preserveAspectRatio="xMinYMin">
            <polygon class="compare-background-triangle" points="270,3 134.9,18 0,3 0,0 270,0 "/>
          </svg>
        </div>

        <div class="d-flex d-md-block">
          <div class="w-100 product-description compare-background">
            <div class="img-wrapper">
              <img [src]="getImageUrl()" class="img-fluid" style="max-width: 300px" alt="Produktbild"/>
            </div>
          </div>
          <div class="w-100">
            <div class="w-100 compare-background accessories-wrapper compare-details pt-md-0 pt-3">
              <ng-container
                *ngIf="variant.accessories && variant.accessories.length; then accessoriesContent else accessoriesEmpty"></ng-container>
              <ng-template #accessoriesContent>
                <div class="accessories-list">
                  <ul>
                    <li *ngFor="let accessory of variant.accessories" class="accessories">
                      <p>{{ accessory.name }}</p>
                    </li>
                  </ul>
                </div>
              </ng-template>
              <ng-template #accessoriesEmpty>
                <h5 class="empty-field"><i class="fa fa-times"></i>ohne Zubehör</h5>
              </ng-template>
            </div>

            <div class="w-100 compare-background contact-price-container compare-details">
              <h4>{{ variant.formatPrice(variant.getTotalPrice()) }}</h4>
            </div>

            <div class="w-100 compare-background download-wrapper compare-details pt-0">
              <ng-container
                *ngIf="variant.documents && variant.documents.length; then documentsContent else documentsEmpty"></ng-container>
              <ng-template #documentsContent>
                <div class="documents-list text-left text-md-center">
                  <a *ngFor="let document of variant.documents"
                     href="{{ document.url }}"
                     target="_blank"
                     class="document-download justify-content-md-center justify-content-start"
                  ><i class="fa fa-download"></i>
                    {{ document.description }} (PDF)
                  </a>
                </div>
              </ng-template>
              <ng-template #documentsEmpty>
                <div class="d-md-block empty-field">keine Dokumente</div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-60 col-md-40 pl-md-3">
    <div class="padding-left-60 mt-3 mt-md-0">
      <h2 *ngIf="variant">{{ this.metaInfoService.getInfoValue('contact_data') }}</h2>
      <app-contact-form [variant]="variant" [parent]="myself"></app-contact-form>
    </div>
  </div>
</div>
