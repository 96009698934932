<div  *ngIf="error" class="alert alert-danger">
    {{ error }}
</div>
<div class="lds-css ng-scope" [hidden]="spinnerHidden">
    <div class="wrapper">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <p style="text-align: center !important;">Ihre Nachricht wird sicher an uns übermittelt. <br/>Bitte haben Sie einen
            Augenblick Geduld.</p>
    </div>
</div>

<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" [hidden]="formHidden">
    <input type="hidden" name="variant-id" formControlName="productVariantId"/>
    <div class="form-row"  *ngIf="showPublicUtility">
        <div class="form-group col-md-60">
            <label for="salutation">Stadtwerk<span class="required"> *</span></label>
            <ng-select id="publicUtilityId" [searchable]="true"
                       [items]="publicUtilityOptions"
                       [clearable]="false"
                       [placeholder]="'bitte wählen...'"
                       bindLabel="name"
                       formControlName="publicUtilityId"
            ></ng-select>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-40 col-sm-30 col-md-20">
            <label for="salutation">Anrede<span class="required"> *</span></label>
            <ng-select id="salutation"
                       [items]="salutationOptions"
                       [clearable]="false"
                       [placeholder]="'bitte wählen...'"
                       bindLabel="name"
                       formControlName="salutation"
            ></ng-select>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-60">
            <label for="company-name">Firmenname</label>
            <input type="text" id="company-name" class="form-control" formControlName="companyName"
                   [ngClass]="companyName.errors && (companyName.touched || companyName.dirty) ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('companyName')"
                [showIf]="getErrorFunction('companyName')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60">
            <label for="forename">Vorname<span class="required"> *</span></label>
            <input type="text" id="forename" class="form-control" formControlName="forename"
                   [ngClass]="forename.errors ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('forename')"
                [showIf]="getErrorFunction('forename')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-60">
            <label for="surname">Nachname<span class="required"> *</span></label>
            <input type="text" id="surname" class="form-control" formControlName="surname"
                   [ngClass]="surname.errors ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('surname')"
                [showIf]="getErrorFunction('surname')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60 col-md-45">
            <label for="street">Straße<span class="required"> *</span></label>
            <input type="text" id="street" class="form-control" formControlName="street"
                   [ngClass]="street.errors && (street.touched || street.dirty) ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('street')"
                [showIf]="getErrorFunction('street')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
        <div class="form-group  col-30 col-md-15">
            <label for="house-number">Hausnummer<span class="required"> *</span></label>
            <input type="text" id="house-number" class="form-control" formControlName="houseNumber"
                   [ngClass]="houseNumber.errors ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('houseNumber')"
                [showIf]="getErrorFunction('houseNumber')"
                [classes]="'form-text text-danger form-error align-to-left'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60 col-md-15">
            <label for="zip">PLZ<span class="required"> *</span></label>
            <input type="text" id="zip" class="form-control" formControlName="zip"
                   [ngClass]="zip.errors && (zip.touched || zip.dirty) ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('zip')"
                [showIf]="getErrorFunction('zip')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
        <div class="form-group col-60 col-md-45">
            <label for="city">Ort<span class="required"> *</span></label>
            <input type="text" id="city" class="form-control" formControlName="city"
                   [ngClass]="city.errors && (city.touched || city.dirty) ? 'is-invalid' : ''"
                   title="Bitte geben Sie Ihren Wohnohrt an."/>
            <app-tooltip
                [message]="getErrorMessage('city')"
                [showIf]="getErrorFunction('city')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60">
            <label for="email">E-Mail<span class="required"> *</span></label>
            <input type="email" id="email" class="form-control" formControlName="email"
                   [ngClass]="email.errors && (email.touched || email.dirty) ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('email')"
                [showIf]="getErrorFunction('email')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60">
            <label for="email-confirmation">E-Mail bestätigen<span class="required"> *</span></label>
            <input type="email" id="email-confirmation" class="form-control"
                   formControlName="emailConfirmation" autocomplete="off"
                   [ngClass]="emailConfirmation.errors && (emailConfirmation.touched || emailConfirmation.dirty) ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('emailConfirmation')"
                [showIf]="getErrorFunction('emailConfirmation')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60">
            <label class="w-100" for="contact-type">Wie möchten Sie kontaktiert werden?<span class="required"> *</span></label>
            <ng-select id="contact-type"
                       [items]="contactTypes"
                       bindLabel="name"
                       formControlName="contactType"
                       [placeholder]="'bitte wählen...'"
                       [ngClass]="contactType.errors ? 'is-invalid' : ''"
                       [clearable]="false"
            ></ng-select>
        </div>
    </div>

    <div *ngIf="showPhone" class="form-row">
        <div class="form-group col-60">
            <label for="phone">Telefonnummer<span class="required"> *</span></label>
            <input type="text" id="phone" class="form-control" formControlName="phone"
                   [ngClass]="phone.errors ? 'is-invalid' : ''"/>
            <app-tooltip
                [message]="getErrorMessage('phone')"
                [showIf]="getErrorFunction('phone')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-40 col-md-30 col-lg-20">
            <label for="installation_wanted">Installation gewünscht?<span class="required"> *</span></label>
            <ng-select id="installation_wanted"
                       [items]="installationWantedOptions"
                       bindLabel="name"
                       formControlName="installationWanted"
                       [placeholder]="'bitte wählen...'"
                       [ngClass]="installationWanted.errors ? 'is-invalid' : ''"
                       [clearable]="false"
            ></ng-select>
        </div>

        <div *ngIf="showInstallationWantedHintText" class="form-group col-60 col-lg-40 pl-lg-3 hint">
            <label>Hinweis</label>
            <p>Gerne machen wir Ihnen zusätzlich zum Angebot zur Ladetechnik (nur die Hardware) auch ein Angebot zur Installation.<br>
                Aufgrund der verschiedenen möglichen Gegebenheiten am Installationsort werden wir hierzu mit Ihnen noch in Kontakt treten.</p>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-60">
            <label for="comment">Bemerkungen</label>
            <textarea class="form-control" id="comment" formControlName="comment" rows="3"
                      [ngClass]="((comment.value?.length < commentMaxLength && comment.value?.length > 0)) ? 'is-valid' : (comment.value == null ? '' : 'is-invalid')"
                      [placeholder]="metaInfoService.hasInfo('contact_form_comments_placeholder') ? metaInfoService.getInfoValue('contact_form_comments_placeholder') : ''"></textarea>
            <small class="text-light float-right">{{ remainingChars }}</small>
            <app-tooltip
                [message]="getErrorMessage('comment')"
                [showIf]="getErrorFunction('comment')" class="textarea-error-tooltip"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>


    <div class="form-row mb-3">
        <div class="form-group col-60 checkbox-group">
            <input type="checkbox" id="accept-tos" formControlName="acceptTOS"/>
            <label class="checkbox-label-text-small" for="accept-tos">
                Ich erkläre meine Einwilligung zur Erhebung, Verarbeitung oder Nutzung meiner personenbezogenen Daten
                gemäß der <a [href]="metaInfoService.getInfoValue('privacy_policy_url')"
                             target="_blank">Datenschutzerklärung.</a><span class="required"> *</span>
            </label>
            <app-tooltip
                [message]="getErrorMessage('acceptTOS')"
                [showIf]="getErrorFunction('acceptTOS')"
                [classes]="'form-text text-danger form-error'"
                [iconClass]="'fa-exclamation-circle'"
            ></app-tooltip>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-60">
            <button  *ngIf="isValid()" type="submit" class="btn btn-primary" [disabled]="!isValid()">
                Angebot anfordern
            </button>

            <button *ngIf="!isValid()" type="button" class="btn btn-dark" (click)="highlightIncompleteFields()">
                Angebot anfordern
            </button>
        </div>
    </div>
    <div class="form-row">
        <p>* Pflichtfeld</p>
    </div>
</form>
