import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    @Input() step: 1|2|3;

    constructor() { }

    ngOnInit(): void {
    }

    getClassesForStep(step: 1|2|3): string {
        return this._getClass(step);
    }

    _getClass(step: 1|2|3): string {
        if (this.step > step) {
            return 'active';
        }
        else if (this.step == step) {
            return 'active current';
        }
        else {
            return '';
        }
    }
}
