<app-progress-bar step="3"></app-progress-bar>
<div class="row">
  <div class="col-md-60 text-center">
    <h1>{{ this.metaInfoService.getInfoValue('app_name') }}</h1>
  </div>
</div>


<div class="row">
  <div class="col-60 text-center">
    <h2>Vielen Dank für Ihr Interesse.</h2>

  </div>
</div>
<div class="row mb-3">
  <div class="col-60 col-lg-45 mx-auto text-center">
    <p class="w-100 mb-3">
      Wir haben Ihre Anfrage erhalten und werden diese umgehend bearbeiten.
      Über den von Ihnen ausgewählten Kontaktweg <span *ngIf="contactResponse.contactType">"{{ contactResponse.contactType }}"</span> wird Sie eine(r) unserer Fachberater:innen kontaktieren
      und Sie über unsere {{ this.metaInfoService.getInfoValue('confirmation_product_description') }} weiter informieren. Für weitere Informationen besuchen Sie bitte auch folgende Seite:
    </p>
  </div>
</div>
<form>
  <div class="row">
    <div class="col-60">
      <div class="text-center">
        <button [hidden]="!this.metaInfoService.hasInfo('more_information_button_href')" (click)="clickButton()" class="btn btn-secondary">
            {{ this.metaInfoService.getInfoValue('more_information_button_text') }}
        </button>
      </div>
    </div>
  </div>
</form>
