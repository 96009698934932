<div *ngIf="variant" class="variant">
  <div class="col-60 top-container">
    <div class="inner">
      <h4>{{ variant.productName }}</h4>
      <p class="power">{{ variant.chargerConnections }} x {{ variant.maximumChargingCapacity.toLocaleString('de-DE') }}
        kW</p>
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 270 21" xml:space="preserve"  preserveAspectRatio="xMinYMin">
          <polygon class="compare-background-triangle" points="270,3 134.9,18 0,3 0,0 270,0 "/>
    </svg>

  </div>
  <div #row class="col-60 product-description compare-background">
    <div class="borderline">
      <img *ngIf="variant.images[0]" src="{{ variant.images[0].url }}" (load)="onImgLoadResize()" alt="Bild Ladesäule"
           style="height:150px;"/>
      <img *ngIf="!variant.images[0]" src="assets/img/product-{{colIndex%3+1}}.png" alt="vorschlag" style="height:150px;"/>
    </div>
  </div>
  <div #row id="accessories-col-{{colIndex}}" class="col-md-60 compare-background accessories-wrapper compare-details ">
    <ng-container
      *ngIf="variant.accessories && variant.accessories.length > 0; then accessoryContent else accessoryEmpty"></ng-container>
    <ng-template #accessoryContent>
      <ul>
          <li *ngFor="let accessory of variant.accessories"
              class="accessories"><i class="fa fa-circle"></i>
              {{ accessory.name }}
          </li>
      </ul>
    </ng-template>
    <ng-template #accessoryEmpty>
      <div class="d-block empty-field">
          <p>ohne Zubehör</p>
      </div>
    </ng-template>
  </div>
  <div #row id="price-col-{{colIndex}}" class="col-md-60 compare-background price-container compare-details">
    <h4>{{ variant.formatPrice(variant.getTotalPrice()) }}</h4>
  </div>
  <div #row id="button-col-{{colIndex}}" class="col-md-60 compare-details compare-background button-container">
    <a routerLink="/contact" class="btn btn-primary" (click)="setVariantOnService()">Angebot anfordern</a>
  </div>
  <div #row id="download-col-{{colIndex}}" class="col-md-60 compare-background download-wrapper compare-details"
       style="min-height: 3rem !important;">
    <ng-container
      *ngIf="variant.documents && variant.documents.length > 0; then documentsContent else documentsEmpty"></ng-container>
    <ng-template #documentsContent>
      <a *ngFor="let document of variant.documents"
         target="_blank"
         href="{{ document.url }}"
         class="document-download"
      ><i class="fa fa-download"></i>
        {{ document.description }} (PDF)
      </a>
    </ng-template>
    <ng-template #documentsEmpty>
      <div class="d-block empty-field">keine Dokumente</div>
    </ng-template>
  </div>
</div>
<div *ngIf="!variant">
  <alert type="info">Keine passenden Produkte gefunden</alert>
</div>
