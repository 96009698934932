import {Component, OnInit, ViewChild} from '@angular/core';
import {DataOptionsService} from './data-options.service';
import {
  Accessory,
  Authorization,
  Backend,
  ChargerConnection,
  ChargingCapacity,
  DataOption,
  DataOptionGrouping,
  InstallationType,
  Standardized
} from './DataOption';
import {Selection} from './Selection';
import {LabelInfoService} from './label-info.service';
import {LabelInfo} from './LabelInfo';
import {environment} from '../../environments/environment';
import {VariantRepository} from "./VariantRepository";
import {Variant} from "./suggestion/Variant";
import {LastSelectionService} from "../last-selection.service";
import {VariantFilter} from "./VariantFilter";
import {ActivatedRoute} from "@angular/router";
import {SuggestionService} from "./suggestion/suggestion.service";
import {RequestIdentificationService} from "./request-identification.service";
import {PublicUtilityService} from "../contact/public-utility.service";
import {SubMandateService} from "../contact/sub-mandate.service";
import {MetaInfoService} from "../meta-info.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-variant-selection',
  templateUrl: './variant-selection.component.html',
  styleUrls: ['./variant-selection.component.scss']
})
export class VariantSelectionComponent implements OnInit {
  selection: Selection;

  fullDataOptions: DataOptionGrouping;
  chargingCapacities: ChargingCapacity[];
  authorizations: Authorization[];
  installationTypes: InstallationType[];
  allInstallationTypes: InstallationType[];
  backends: Backend[];
  accessories: Accessory[];
  variantRepository: VariantRepository;
  chargerConnections: ChargerConnection[];

  standardized: Standardized[];
  @ViewChild('accessoriesSelect', { static: true }) accessoriesSelect;

  labelInfos: LabelInfo = new LabelInfo();
  loading = false;
  loadingText = 'Verfügbare Produktkonfigurationen werden aus dem Backend geladen...';

  constructor(
    private dataOptionService: DataOptionsService,
    private labelInfoService: LabelInfoService,
    private lastSelectionService: LastSelectionService,
    private requestIdentificationService: RequestIdentificationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public metaInfoService: MetaInfoService
  ) {
      if (metaInfoService.hasInfo('app_name_selection')) {
          titleService.setTitle(metaInfoService.getInfoValue('app_name_selection'));
      }
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      PublicUtilityService.publicUtilityFromUrl = params.get('sw');
      SubMandateService.referral = params.get('sm');
    });
    this.loading = true;
    this.getDataOptions();
    this.getLabelInfos();

    this.requestIdentificationService.retrieveHashIdentifier().subscribe((response) => {
      this.requestIdentificationService.hashIdentifier = response.hashIdentifier;
    });


    if (this.lastSelectionService.selection) {
      this.loadingText = 'Ihre vorige Konfiguration wird geladen...';
      this.selection = this.lastSelectionService.selection;
      setTimeout(() => this.selection.onChange(), 0);
      this.loading = false;
    } else {
      this.selection = new Selection();
    }
  }

  getDataOptions(): void {
    this.dataOptionService.getOptions()
      .subscribe(options => {
        this.fullDataOptions = options;
        this.chargingCapacities = options.chargingCapacities;
        this.authorizations = options.authorizations;
        this.installationTypes = options.installationTypes;
        this.allInstallationTypes = options.installationTypes;
        this.backends = options.backends;
        this.accessories = options.accessories;
        const variantsArray = [];
        options.variants.forEach(plain => {
          variantsArray.push(Variant.createFromDataOption(plain));
        });
        this.variantRepository = new VariantRepository(variantsArray);
        this.standardized = [
          new Standardized(1, 'ja'),
          new Standardized(0, 'nein'),
        ];
        this.chargerConnections = options.chargerConnections;
        this.loading = false;
      });
  }

  getLabelInfos(): void {
    this.labelInfoService.getInfo()
      .subscribe(infos => this.labelInfos = infos);
  }

  isDebug(): boolean {
    return !environment.production;
  }

  update(): void {
    this.selection.installationType = this._getItemById(1, this.installationTypes) || new InstallationType(1);
    this.selection.chargingCapacity = this._getItemById(1, this.chargingCapacities) || new ChargingCapacity(1);
    this.selection.chargerConnections = this._getItemById(1, this.chargerConnections) || new ChargerConnection(1);
  }

  private _getItemById(id: number, arr: DataOption[]) {
    return arr.find((option) => {
      return option.id === id;
    });
  }

  resetSelection(select: string) {
    const items = this[select].selectedItems;
    items.forEach((item) => {
      this[select].unselect(item);
    });
  }

  updateChargerConnectionsSelect(): void {
    const filter = VariantFilter.fromSelection(this.selection, ['chargingCapacity']);
    const ids = this.variantRepository.get('chargerConnections', filter);
    const options = this.fullDataOptions.chargerConnections;
    this.chargerConnections = options.filter((value: ChargerConnection) => ids.includes(value.id));
  }

  updateInstallationTypeSelect(): void {
    const filter = VariantFilter.fromSelection(this.selection, ['chargingCapacity', 'chargerConnections']);
    const ids = this.variantRepository.get('installationTypeId', filter);
    const options = this.fullDataOptions.installationTypes;
    this.installationTypes = options.filter((value: InstallationType) => ids.includes(value.id));
  }

  updateAccessorySelect(): void {
    const filter = VariantFilter.fromSelection(this.selection, VariantFilter.allFields.filter(
      f => f !== 'accessories'
    ));
    const ids = this.variantRepository.get('accessories', filter);
    const options = this.fullDataOptions.accessories;
    const currentSelection = this.selection.accessories.map((value: Accessory) => value.id);

    this.accessories = options.filter((value: Accessory) => ids.includes(value.id) || currentSelection.includes(value.id));
  }

  updateAuthorizationSelect(): void {
    const filter = VariantFilter.fromSelection(this.selection, VariantFilter.allFields.filter(
      f => f !== 'authorization'
    ));
    const ids = this.variantRepository.get('chargingAuthorization', filter);
    const options = this.fullDataOptions.authorizations;
    this.authorizations = options.filter((value: Accessory) => ids.includes(value.name));
  }

  updateStandardizedSelect(): void {
    // todo: missing data
  }

  onSelectChange(field: string): void {
    if (field !== 'chargerConnections') {
      this.updateChargerConnectionsSelect();
    }
    if (field !== 'installationTypes') {
      this.updateInstallationTypeSelect();
    }
    if (field !== 'accessories') {
      this.updateAccessorySelect();
    }
    if (field !== 'authorizations') {
      this.updateAuthorizationSelect();
    }
    if (field !== 'standardized') {
      this.updateStandardizedSelect();
    }
  }
}

