
<div class="container">
    <div class="ew-progress-bar d-flex w-100">
        <div class="step first {{ getClassesForStep(1) }}"><span class="text">1</span><i class="fas fa-check"></i></div>
        <div class="progress-line {{ getClassesForStep(2) }}"></div>
        <div class="step second {{ getClassesForStep(2) }}"><span class="text">2</span><i class="fas fa-check"></i></div>
        <div class="progress-line {{ getClassesForStep(3) }}"></div>
        <div class="step third {{ getClassesForStep(3) }}"><span class="text">3</span><i class="fas fa-check"></i></div>
    </div>
</div>
