<div *ngIf="variants && variants.length > 0" class="w-100 compare">
  <div class="row">
    <div *ngFor="let variant of variants; let colIndex = index;" class="col-60 col-md-20 col-lg-20 compare-column">
      <app-variant
        [colIndex]="colIndex + 1"
        [variant]="variant"
        [maxHeights]="valueCounters"
        [parent]="this"
      ></app-variant>
    </div>
  </div>
</div>
<div *ngIf="!variants || variants.length < 1" class="small-container mx-auto">
  <alert type="info" *ngIf="selection.isComplete() && !isLoading">
    {{ this.metaInfoService.getInfoValue('suggestion_no_result') }}
  </alert>
  <alert type="info" *ngIf="selection.isComplete() && isLoading">
    Produkte werden geladen...
  </alert>
  <alert type="info" *ngIf="!selection.isComplete()">
    Bitte geben Sie mindestens die folgenden Informationen an, um Produktvorschläge zu erhalten:
    <ul>
      <li>Ladeleistung</li>
      <li>Anzahl der Ladepunkte</li>
      <li>Installationsart</li>
    </ul>
  </alert>
</div>

